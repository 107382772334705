import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _BackToForum;
import Component from "@glimmer/component";
import { LinkTo } from "@ember/routing";
import { service } from "@ember/service";
import { ADMIN_PANEL } from "discourse/lib/sidebar/panels";
import { defaultHomepage } from "discourse/lib/utilities";
import dIcon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let BackToForum = (_class = (_BackToForum = class BackToForum extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  get shouldDisplay() {
    return this.sidebarState.isCurrentPanel(ADMIN_PANEL);
  }
  get homepage() {
    return `discovery.${defaultHomepage()}`;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.shouldDisplay}}
      <LinkTo @route={{this.homepage}} class="sidebar-sections__back-to-forum">
        {{dIcon "arrow-left"}}

        <span>{{i18n "admin.back_to_forum"}}</span>
      </LinkTo>
    {{/if}}
  
*/
{
  "id": "Fn0dgxBJ",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"sidebar-sections__back-to-forum\"]],[[\"@route\"],[[30,0,[\"homepage\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"arrow-left\"],null]],[1,\"\\n\\n        \"],[10,1],[12],[1,[28,[32,2],[\"admin.back_to_forum\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/back-to-forum.js",
  "scope": () => [LinkTo, dIcon, i18n],
  "isStrictMode": true
}), _BackToForum), _BackToForum), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { BackToForum as default };